import { useAuthContext } from "./useAuthContext";


export const useLogout =  () => {

  const { dispatch } = useAuthContext()

  const logout = async () => {

    dispatch({type: "LOGOUT"})
    window.location.href = "https://echo-backend.resilientdb.com/auth/logout";

  }

  return { logout }
}
 
